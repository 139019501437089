@import './mixins.scss';
// @import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,300;0,500;1,100;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,300;0,500;0,600;1,100;1,300;1,500;1,600&display=swap');

html {
  font-size: 14px;
}
@media (max-width: 900px) {
  html { font-size: 12px; }
}
@media (max-width: 400px) {
  html { font-size: 10px; }
}

/* Type will scale with document */
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2.5rem;
}
h3 {
  font-size: 2rem;
}

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@mixin button-mixin{
  display: inline-block;
	border-radius: $radius-small;
	padding: $standard-padding;
	border: 1px solid $border-colour;
	background-image: linear-gradient(#EEE, #DDD);
}

.button {
@include button-mixin;
  &--state-success {
    @include button-mixin;
    color: #FFF;
	background: #569E3D linear-gradient(#006966, #015c59) repeat-x;
	border-color: #4A993E;
  };
  &--state-danger {
    @include button-mixin;
    background: #3E70AE linear-gradient(#3E70AE, #3a6ba7) repeat-x;

    
    color: #ffffff;
  };
  &.rounded{
    border-radius: $radius-large;
  }
}


.pad-left{
  margin-left: .5rem;
}
.pad-right{
  margin-right: .5rem;
}

.dropdown-cell{
  padding: $standard-padding;
	border: 1px solid $border-colour;
  background-color: rgb(255, 255, 255);
  transition: background-color 0.5s ease ;
  &:hover{
    background-color: rgb(245, 245, 245);
  }
}

input {
  padding: $standard-padding;
	border-radius: $radius-small;
  border: 1px solid;
  border-color: #acacac;
}

.hidden{
  display: none;
}
