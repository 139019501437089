.biog{
    font-size: 1.2rem;
    border-radius: 0 50px 50px 0;
    width: 300px;
    background-color: #7080096b;
    z-index: 2;
    position: fixed;
    top: 50px;
    left: 0;
    color: #ffffff;
    padding: 30px 30px 30px 60px;
    margin: 0;
}

a{
    fill:chartreuse;
}